import styled, { keyframes } from 'styled-components';

// export const PaginationWrapper = styled.div`
//   display: inline-block;
//   width: 100%;
//   margin: 100px 0;
//   height: 50px;
// `;

export const PaginationList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  opacity: 1;
  transition: opacity 0.3s ease;
`;

export const PageItem = styled.li`
  margin: 0 5px;
  transition: transform 0.3s ease;

  @media only screen and (max-width: 525px) {
    font-size: 12px;
    margin: 0 2px;
  }
`;

export const PageButton = styled.button<{ isActive?: boolean }>`
  padding: 5px 10px;
  background-color: ${({ isActive }) => (isActive ? 'blue' : 'white')};
  color: ${({ isActive }) => (isActive ? 'white' : 'black')};
  border: 1px solid blue;
  border-radius: 5px;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    background-color 0.2s ease,
    color 0.2s ease; // Add transitions

  @media only screen and (max-width: 525px) {
    padding: 4px 8px;
  }

  &:hover {
    background-color: blue; // Change background color on hover
    color: white; // Change text color on hover
  }

  &:active {
    transform: scale(0.95); // Add a scale effect when button is pressed
  }
`;
