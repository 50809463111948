import React, { useLayoutEffect, useState } from 'react';
import FilterButton from '../../shared/components/filter-button';
import {
  ArticlesFilter,
  ArticlesTitle,
  ArticlesTitleText,
} from '../home/components/articles/home-articles.style';
import {
  HomeWebinarDesc,
  WebinarsContainer,
  WebinarsWrapper,
} from './index.styles';
import WebinarCard from '../../shared/components/webinar-card';
import { useQuery } from 'react-query';
import { userService } from '../../shared/services/user.service';
import { showToastError } from '../../shared/components/toast/toast';
import { useNavigate } from 'react-router-dom';
import { RouterKeys } from '../../router/keys';
import Pagination from '../../shared/components/paginations';
import AppLoader from '../../shared/components/loader/loader';
import { useWindowSize } from '../../shared/hooks/useScreenSize';
import {
  CategoriesSelect,
  LoaderContainer,
  StyledSelect,
} from '../articles/index.styles';
import { useGetAllCategories } from '../../shared/hooks/use-get-all-categories.hook';

const WebinarsPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  const [listOfWebinars, setListOfWebinars] = useState<any>();
  const [listOfFilteredWebinars, setListOfFilteredWebinars] = useState<any>();
  const [categoryID, setCategoryID] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [category, setCategory] = useState<any>(null);
  const { width } = useWindowSize();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    setIsLoading(true);
  }, []);

  const { isLoading: load } = useQuery<any, any>(
    ['webinarsByCategory', { limit, page, categoryID }],
    () => userService.getWebinarsByCategory(categoryID, limit, page),
    {
      keepPreviousData: true,
      enabled: !!categoryID,
      onSuccess(res: any) {
        setListOfFilteredWebinars(res.data.data);
        //setWebinarData(res.data);
        setTotalItems(res.data.total);
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
        showToastError('Щось пішло не так');
      },
    }
  );

  const { options } = useGetAllCategories();

  const webinarsData = useQuery<any, any>(
    ['webinars', { limit, page }],
    () => userService.getWebinars(limit, page),

    {
      keepPreviousData: true,
      onSuccess(res: any) {
        setListOfWebinars(res.data.data);
        setTotalItems(res.data.total);
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
        showToastError('Щось пішло не так');
      },
    }
  );

  const webinarHandler = (id: any) => {
    navigate(`/${RouterKeys.WEBINARS}/${id}`);
  };

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
  };

  const filterHandler = (id: number) => {
    setCategoryID(id);
  };

  const handleChange = (selectedOption: any) => {
    setCategoryID(selectedOption.id);
    setCategory(selectedOption);
  };

  if (isLoading)
    return (
      <LoaderContainer>
        <AppLoader />
      </LoaderContainer>
    );

  return (
    <WebinarsWrapper>
      <WebinarsContainer>
        <ArticlesTitle>
          <ArticlesTitleText>Вебінари</ArticlesTitleText>
        </ArticlesTitle>
        <p
          style={{
            fontSize: 40,
            fontWeight: 600,
            marginBottom: 150,
            marginTop: 100,
            flex: 1,
            textAlign: 'center',
          }}
        >
          Coming soon...
        </p>
        <HomeWebinarDesc>
          Ми вже працюємо над тим, щоб в цьому розділі зʼявились вебінари, від
          топових спеціалістів з України. Їх можна буде придбати та переглядати
          в особистому кабінеті не залежно від підписки на статті.
        </HomeWebinarDesc>
        {/*{width < 819 ? (
          <CategoriesSelect>
            <StyledSelect
              options={options}
              getOptionLabel={options => options['name']}
              placeholder={'Категорія'}
              getOptionValue={options => options['id']}
              value={category}
              onChange={values => handleChange(values)}
            />
          </CategoriesSelect>
        ) : (
          <ArticlesFilter>
            {options?.map((button: any, index: number) => {
              return (
                <FilterButton
                  key={button.name}
                  id={index + 1}
                  onClick={filterHandler}
                  text={button.name}
                />
              );
            })}
          </ArticlesFilter>
        )}

        {categoryID
          ? listOfFilteredWebinars?.map((item: any) => {
              return (
                <WebinarCard
                  key={item.title}
                  onClick={webinarHandler}
                  id={item.id}
                  author={item.author}
                  title={item.title}
                  category={item.categories[0].name}
                  price={item.price}
                  imageUrl={item.featured_image}
                />
              );
            })
          : listOfWebinars?.map((item: any) => {
              return (
                <WebinarCard
                  key={item.title}
                  onClick={webinarHandler}
                  id={item.id}
                  author={item.author}
                  title={item.title}
                  category={item.categories[0].name}
                  price={item.price}
                  imageUrl={item.featured_image}
                />
              );
            })}
        <div style={{ marginTop: 40 }}>
          {Math.ceil(totalItems / limit) > 1 ? (
            <Pagination
              totalItems={totalItems}
              itemsPerPage={limit}
              currentPage={page}
              onPageChange={handlePageChange}
            />
          ) : (
            <></>
          )}
        </div>*/}
      </WebinarsContainer>
    </WebinarsWrapper>
  );
};

export default WebinarsPage;
