import styled from 'styled-components';

export const SearchInput = styled.input`
  font-family: 'Montserrat-Medium';
  margin: 10px 0 10px 0;
  padding-left: 8px;
  height: 40px;
  width: 25%;
  border-radius: 8px;
  border: 1px solid #a0aac2;
  transition: box-shadow 0.3s;

  &:focus {
    outline: none;
    border-color: #5a9fff;
    box-shadow: 0 0 5px rgba(90, 159, 255, 0.5);
  }

  @media only screen and (max-width: 610px) {
    height: 30px;
    width: 45%;
  }
`;
