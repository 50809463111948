import * as React from 'react';
import { Routes } from 'react-router-dom';

import { adminRoutes, privateRoutes } from './routes';

const Router: React.FunctionComponent = () => {
  return (
    <Routes>
      {privateRoutes}
      {adminRoutes}
    </Routes>
  );
};
export default Router;
