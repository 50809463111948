import React from 'react';
import { LoaderContainer, LoaderItem } from './loader-signin.styles';

const AppLoaderSignin = () => {
  return (
    <LoaderContainer>
      <LoaderItem />
    </LoaderContainer>
  );
};

export default AppLoaderSignin;
