import React from 'react';
import { ArticlesTitleText, FaqTitle } from '../articles/home-articles.style';
import { FAQContainer, FAQWrapper } from './FAQ.styles';
import FAQItem from './FAQItem';
import logo from '../../../../assets/icons/shortLogo.svg';
import { PriceImage } from '../price/Price.styles';

const faqArr = [
  {
    title: 'Як отримати доступ до статей ?',
    desc: 'На нашому сайті є відкриті та закриті статті. З відкритими статтями на сайті можна ознайомитись безкоштовно. Щоб отримати доступ до закритих статей, вам потрібно обрати підписку та оплатити її. Після цього вам буде надано доступ до всіх статей на сайті IQDental.com.ua',
  },
  {
    title: 'Як здійснюється оплата ?',
    desc: 'Підписка на статті здійснюється лише через наш сайт. Після вибору тарифу та натискання кнопки «Обрати» відкриється вікно сервісу «liqpay.ua». Там ви зможете обрати зручний для вас варіант оплати та скористатися ним. Після успішної оплати, вам надійде повідомлення на електронну пошту. Будьте обережні, інших способів оплати підписки – не існує. Оплата проводиться тільки через сайт IQDental.com.ua.',
  },
  {
    title: 'Термін дії підписки ?',
    desc: 'Ми пропонуємо обрати два варіанти підписки з терміном на 3 та 6 місяців. З моменту оплати в вашому особистому кабінеті зʼявиться інформація про кількість днів, яка залишилась до кінця підписки. Після закінчення терміну дії підписки, у вас обмежиться доступ до закритих статей. Щоб поновити доступ, необхідно знову здійснити оплату.',
  },
  {
    title:
      'Я знайшов помилку на сайті, або у мене виникли якісь труднощі з оплатою. Що я можу зробити ?',
    desc: 'Ми молода команда, яка дбає про те, щоб ресурс був сервісним та зручним у використані. Ми постійно оновлюємо та вдосконалюємо наш сайт. Якщо у вас виникли якісь труднощі, зателефонуйте, або повідомте нам на пошту, контактна інформація знаходиться на сайті. Ми швидко вирішимо проблему.',
  },
  {
    title: 'Що таке вебінари ?',
    desc: `На сайті є розділ з вебінарами, їх кількість постійно збільшується. Це лекції найкращих фахівців України. Кожна іконка в розділі вебінарів представляє один урок. До нього може входити одна або кілька лекцій на загальну тему. Доступ до відео-лекцій можна отримати після реєстрації на сайті IQDental.com.ua, оплативши відповідний урок. <strong>Вебінари не входять у вартість підписки.</strong> За підписку ви отримуєте лише доступ до статей. Вебінари доступні в особистому кабінеті обмежений період часу.`,
  },
  {
    title: 'Як я можу співпрацювати з вами ?',
    desc: 'Якщо у вас є ідеї, або цікаві пропозиції щодо вдосконалення сайту, співпраці, колаборацій і тд, ви можете звʼязатись з нами через пошту або мобільний звʼязок. Контактна інформація є на сайті.',
  },
  {
    title: 'Чи можу я копіювати чи поширювати інформацію з сайту ?',
    desc: 'Копіювати статті, протоколи чи записувати вебінари з платформи IQDental.com.ua – заборонено. Ми залишаємо за собою право блокувати користувачів за плагіат інформації. Будьте чемними та відносьтесь до нашої інтелектуальної праці з повагою. Давайте підвищувати рівень української стоматології разом.',
  },
];

const FAQ = () => {
  return (
    <FAQWrapper id="faq">
      <FAQContainer>
        <FaqTitle>
          <PriceImage src={logo} alt="articles" />
          <ArticlesTitleText>FAQ</ArticlesTitleText>
        </FaqTitle>
        {faqArr.map(item => {
          return (
            <FAQItem key={item.desc} title={item.title} desc={item.desc} />
          );
        })}
      </FAQContainer>
    </FAQWrapper>
  );
};

export default FAQ;
