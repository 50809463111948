import { H } from 'highlight.run';

export function initializeHighlight() {
  H.init(process.env.REACT_APP_HIGHLIGHT_ID, {
    serviceName: 'IQDental-frontend',
    tracingOrigins: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        'https://www.googleapis.com/identitytoolkit',
        'https://securetoken.googleapis.com',
      ],
    },
  });
}
