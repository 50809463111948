import React, { ChangeEvent, FC } from 'react';
import { SearchInput } from './index.styles';

interface ISearchInput {
  search: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholderName: string;
}

const SearchInputComponent: FC<ISearchInput> = ({
  search,
  onChange,
  placeholderName,
}) => {
  return (
    <SearchInput
      type="text"
      placeholder={placeholderName}
      value={search}
      onChange={onChange}
    />
  );
};

export default SearchInputComponent;
