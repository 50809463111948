import axios from 'axios';
import { AdminEndpoints } from '../../constants/endpoints-keys';
import { HttpFactoryService } from '../http-factory.service';
import type { HttpService } from '../http.service';
import { mainAxios } from '../mainAxios';

const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
class AdminService {
  constructor(private readonly httpService: HttpService) {}

  public async login(body: any) {
    return await mainAxios.post(`${baseUrl}/${AdminEndpoints.LOGIN}`, body, {
      withCredentials: true,
    });
  }

  public async createWebinar(body: any) {
    await mainAxios.post(`${baseUrl}/${AdminEndpoints.CREATE_WEBINAR}`, body, {
      withCredentials: true,
    });
    //await this.httpService.post(AdminEndpoints.CREATE_WEBINAR, body);
  }

  public async createPost(body: any) {
    await mainAxios.post(`${baseUrl}/${AdminEndpoints.CREATE_POST}`, body, {
      withCredentials: true,
    });
    //await this.httpService.post(AdminEndpoints.CREATE_POST, body);
  }

  public async updatePost(body: any) {
    await mainAxios.put(
      `${baseUrl}/${AdminEndpoints.UPDATE_POST}/${body.id}`,
      body,
      {
        withCredentials: true,
      }
    );
    //await this.httpService.post(AdminEndpoints.CREATE_POST, body);
  }

  public async getAllCategories() {
    //await mainAxios.get(`${baseUrl}/${AdminEndpoints.GET_ALL_CATEGORIES}`, {
    //  withCredentials: true,
    //});
    return await this.httpService.get(AdminEndpoints.GET_ALL_CATEGORIES);
  }

  public async check() {
    return await mainAxios.get(`${baseUrl}/${AdminEndpoints.VERIFY_TOKEN}`, {
      withCredentials: true,
    });
  }

  public async uploadFile(body: any): Promise<any> {
    return await mainAxios.post(
      `${baseUrl}/${AdminEndpoints.UPLOAD_FILE}`,
      body,
      {
        withCredentials: true,
      }
    );
    //return this.httpService.post(AdminEndpoints.UPLOAD_FILE, body);
  }

  public async uploadMainImage(body: any): Promise<any> {
    return axios.post(
      `${process.env.REACT_APP_SERVER_URL}/${AdminEndpoints.UPLOAD_MAIN_IMAGE}?folderName=test`,
      body
    );
  }

  public async uploadVideo(body: any): Promise<any> {
    return axios.post(
      `${process.env.REACT_APP_SERVER_URL}/${AdminEndpoints.UPLOAD_VIDEO}`,
      body
    );
  }

  public async getArticles(limit: number, page: number) {
    return await mainAxios.get(
      `${baseUrl}/${AdminEndpoints.GET_ARTICLES}?limit=${limit}&skip=${
        (page - 1) * limit
      }`,
      {
        withCredentials: true,
      }
    );
    //return await this.httpService.get(
    //  `${AdminEndpoints.GET_ARTICLES}?limit=${limit}&skip=${(page - 1) * limit}`
    //);
  }

  public async getArticlesByTitle(title: string) {
    return await mainAxios.get(
      `${baseUrl}/${AdminEndpoints.CREATE_POST}/title/admin?title=${title}`,
      {
        withCredentials: true,
      }
    );
    //return await this.httpService.get(
    //  `${AdminEndpoints.CREATE_POST}/admin/title?title=${title}`
    //);
  }

  public async deleteArticleById(id: any) {
    return await mainAxios.delete(
      `${baseUrl}/${AdminEndpoints.DELETE_ARTICLES}/${id}`,
      {
        withCredentials: true,
      }
    );
    //return this.httpService.delete(`${AdminEndpoints.DELETE_ARTICLES}/${id}`);
  }

  public async updateArticleSlider(id: number, sliderStatus: boolean) {
    return await mainAxios.put(
      `${baseUrl}/${AdminEndpoints.UPDATE_ARTICLES_SLIDER}/${id}`,
      {
        sliderStatus,
      },
      {
        withCredentials: true,
      }
    );
    //return this.httpService.delete(`${AdminEndpoints.DELETE_ARTICLES}/${id}`);
  }

  public async deleteWebinarById(id: any) {
    return await mainAxios.delete(
      `${baseUrl}/${AdminEndpoints.CREATE_WEBINAR}/${id}`,
      {
        withCredentials: true,
      }
    );
    //return this.httpService.delete(`${AdminEndpoints.CREATE_WEBINAR}/${id}`);
  }

  public async getAllBlockUsers() {
    return await mainAxios.get(
      `${baseUrl}/${AdminEndpoints.ALL_BLOCKED_USERS}`,
      {
        withCredentials: true,
      }
    );
  }
  public async addUserToBlackList(userId: number) {
    return await mainAxios.post(
      `${baseUrl}/${AdminEndpoints.ADD_USER_TO_BLOCK}`,
      { userId: userId },
      {
        withCredentials: true,
      }
    );
  }
  public async removeUserFromBlackList(userId: any) {
    return await mainAxios.delete(`${baseUrl}/user/${userId}/delete-user`, {
      withCredentials: true,
    });
  }
}

const factory = new HttpFactoryService();
export const adminService = new AdminService(factory.createHttpService());
