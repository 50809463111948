import React, { FC } from 'react';
import { ButtonText, ButtonWrapper, ButtonWrapperActive } from './index.styles';

type IFilterButton = {
  text: string;
  onClick: any;
  width?: string;
  id: any;
  idCategorySearch: number;
};

const FilterButton: FC<IFilterButton> = ({
  text,
  onClick,
  width = '232px',
  id,
  idCategorySearch,
}) => {
  return id === idCategorySearch ? (
    <ButtonWrapperActive onClick={() => onClick(id)} width={width}>
      <ButtonText>{text}</ButtonText>
    </ButtonWrapperActive>
  ) : (
    <ButtonWrapper onClick={() => onClick(id)} width={width}>
      <ButtonText>{text}</ButtonText>
    </ButtonWrapper>
  );
};

export default FilterButton;
