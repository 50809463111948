import React from 'react';
import telegramIcon from '../../../assets/icons/telegram.svg';
import {
  TelegramContainer,
  TelegramIcon,
  NotificationDot,
} from './index.styled';

const AppHelp = () => {
  return (
    <TelegramContainer>
      <a target="_blank" href="https://t.me/IQDentalSupport_bot">
        <TelegramIcon
          src={telegramIcon}
          title="Telegram Chat"
          alt="Telegram Chat"
          width={50}
        />
      </a>
      <NotificationDot />
    </TelegramContainer>
  );
};

export default AppHelp;
