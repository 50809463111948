import { useEffect, useState } from 'react';
import { useAuth } from '../../store/auth';
import { userService } from '../services/user.service';

export const useAuthCheck = (): void => {
  const [user, setUser] = useState();

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const res = await userService.getUser();
        setUser(res.data);
        useAuth.getState().updateAuth(true)();
      } catch (error) {
        useAuth.getState().updateAuth(false)();
      }

      //setUserInfo(auth, user);
    })();
  }, []);
};
