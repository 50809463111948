import styled from 'styled-components';
import { COLORS } from '../../shared/theme';

export const WebinarsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 613px) {
    align-items: none;
    // padding-left: 20px;
  }
`;

export const WebinarsContainer = styled.div`
  width: 1240px;
  margin-top: 50px;
  @media only screen and (max-width: 1265px) {
    max-width: 1100px;
  }
  @media only screen and (max-width: 1120px) {
    max-width: 1000px;
  }
  @media only screen and (max-width: 1030px) {
    max-width: 800px;
  }
  @media only screen and (max-width: 818px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 613px) {
    max-width: 400px;
  }
  @media only screen and (max-width: 407px) {
    max-width: 290px;
  }
`;

export const HomeWebinarDesc = styled.div`
  font-family: OpenSans-Regular;
  text-align: center;
  font-size: 24px;
  color: ${COLORS.greyPrimary};
  margin: 0 20px 48px 20px;
  @media only screen and (max-width: 1030px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 720px) {
    font-size: 15px;
  }
`;
