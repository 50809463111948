import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import ArticleCard from '../../shared/components/article-card/article';
import FilterButton from '../../shared/components/filter-button';
import { userService } from '../../shared/services/user.service';
import {
  ArticlesFilter,
  ArticlesTitle,
  ArticlesTitleText,
} from '../home/components/articles/home-articles.style';
import BannerArticle from './banner-article';
import {
  ArticlesContainer,
  ArticlesListContainer,
  ArticlesWrapper,
  CategoriesSelect,
  LoaderContainer,
  ShowAll,
  StyledSelect,
} from './index.styles';
import AppLoader from '../../shared/components/loader/loader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { showToastError } from '../../shared/components/toast/toast';
import { ToastContainer } from 'react-toastify';
import Pagination from '../../shared/components/paginations';
import Recommendation from '../../shared/components/recommendation';
import { useGetAllCategories } from '../../shared/hooks/use-get-all-categories.hook';

import { useWindowSize } from '../../shared/hooks/useScreenSize';
import SearchInputComponent from '../../shared/components/search-input';

const ArticlesPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [search, setSearch] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [listOfArticles, setListOfArticles] = useState<any>();
  const [listOfFilteredArticles, setListOfFilteredArticles] = useState<any>();
  const [categoryID, setCategoryID] = useState<any>(null);
  const [category, setCategory] = useState<any>(null);
  const [categoryLoader, setCategoryLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const { width } = useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();

  const idCategorySearch = searchParams.get('category') || null;

  const articlesRef: any = useRef(null);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    setCategoryID(searchParams.get('category'));
  }, []);

  const { refetch, isLoading: loadingAllArticles } = useQuery<any, any>(
    ['articles', { limit, page, search }],
    () => userService.getArticles(limit, page, search),
    {
      keepPreviousData: true,
      enabled: categoryID == null && (search.length === 0 || search.length > 2),
      onSuccess(res: any) {
        if (categoryID) return;
        setListOfArticles(res.data.data);
        setTotalItems(res.data.total);
        setIsLoading(false);
        setCategoryLoader(false);
      },
      onError: () => {
        showToastError('Щось пішло не так');
        setCategoryLoader(false);
      },
    }
  );

  const { isLoading: loadingCategoriesArticles } = useQuery<any, any>(
    ['categoryByCategory', { limit, page, categoryID, search }],
    () => userService.getArticlesByCategory(categoryID, limit, page, search),
    {
      keepPreviousData: true,
      enabled: !!categoryID && (search.length === 0 || search.length > 2),
      onSuccess(res: any) {
        setListOfFilteredArticles(res.data.data);
        setTotalItems(res.data.total);
        setIsLoading(false);
        setCategoryLoader(false);
      },
      onError: () => {
        showToastError('Щось пішло не так');
        setCategoryLoader(false);
      },
    }
  );

  const user = useQuery<any, any>('user', () => userService.getUser(), {
    keepPreviousData: true,
    onSuccess(res) {
      res.data.subscription ? setIsSubscribe(true) : setIsSubscribe(false);
    },
  });

  useLayoutEffect(() => {
    setIsLoading(true);
  }, []);

  const { options } = useGetAllCategories();

  const chooseArticleHandle = (id: number) => {
    navigate(`/articles/${id}`);
  };

  const filterHandler = (id: number) => {
    setPage(1);
    setCategoryLoader(true);
    searchParams.set('category', id.toString());
    setSearchParams(searchParams);
    setCategoryID(id);
    //mutation.mutate(id);
  };

  const handlerSearchChange = (event: any) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handlePageChange = (newPage: any) => {
    setPage(1);
    setCategoryLoader(true);
    setPage(newPage);
    articlesRef?.current.scrollIntoView();
  };

  const clearFilterHandler = () => {
    navigate('/articles');
    setCategoryLoader(true);
    setCategoryID(null);
    refetch();
  };

  const handleChange = (selectedOption: any) => {
    setCategoryLoader(true);
    searchParams.set('category', selectedOption.id);
    setSearchParams(searchParams);
    setCategoryID(selectedOption.id);
    setCategory(selectedOption);
  };

  if (isLoading)
    return (
      <LoaderContainer>
        <AppLoader />
      </LoaderContainer>
    );

  return (
    <ArticlesWrapper>
      {!isSubscribe && <BannerArticle />}

      <ArticlesContainer>
        <ArticlesTitle>
          <ArticlesTitleText>Статті</ArticlesTitleText>

          <SearchInputComponent
            search={search}
            onChange={handlerSearchChange}
            placeholderName={
              idCategorySearch ? 'Пошук в категорії...' : 'Пошук...'
            }
          />
        </ArticlesTitle>
        {categoryID && (
          <ShowAll onClick={clearFilterHandler}>Показати всі статті</ShowAll>
        )}
        {width < 819 ? (
          <CategoriesSelect>
            <StyledSelect
              options={options}
              getOptionLabel={options => options?.['name']}
              getOptionValue={options => options?.['id']}
              placeholder={'Категорія'}
              value={category}
              onChange={values => handleChange(values)}
              idCategorySearch={Number(idCategorySearch)}
            />
          </CategoriesSelect>
        ) : (
          <ArticlesFilter>
            {options?.map((button: any, index: number) => {
              return (
                <FilterButton
                  key={button.name}
                  id={button.id}
                  onClick={filterHandler}
                  text={button.name}
                  idCategorySearch={Number(idCategorySearch)}
                />
              );
            })}
          </ArticlesFilter>
        )}

        {categoryLoader ? (
          <AppLoader />
        ) : (
          <>
            <ArticlesListContainer ref={articlesRef}>
              {categoryID
                ? listOfFilteredArticles?.map((article: any) => {
                    return (
                      <ArticleCard
                        key={article.id}
                        onClick={chooseArticleHandle}
                        id={article.id}
                        name={article.author}
                        title={article.title}
                        image={article.featured_image}
                        category={article.categories[0]}
                      />
                    );
                  })
                : listOfArticles?.map((article: any) => {
                    return (
                      <ArticleCard
                        onClick={chooseArticleHandle}
                        id={article.id}
                        key={article.id}
                        name={article.author}
                        title={article.title}
                        image={article.featured_image}
                        category={article.categories[0]}
                      />
                    );
                  })}
            </ArticlesListContainer>
            {totalItems > 6 && (
              <Pagination
                totalItems={totalItems}
                itemsPerPage={limit}
                currentPage={page}
                onPageChange={handlePageChange}
              />
            )}
          </>
        )}
        <Recommendation />
      </ArticlesContainer>
      <ToastContainer />
    </ArticlesWrapper>
  );
};

export default ArticlesPage;
