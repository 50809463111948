import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { ToastContainer } from 'react-toastify';
import {
  AdminContentContainer,
  AdminContentWrapper,
} from 'shared/components/admin/AdminContent.styled';
import AppButton from '../../../shared/components/app-button/AppButton';
import ArticleCard from '../../../shared/components/article-card/article';
import Pagination from '../../../shared/components/paginations';
import {
  showToastError,
  showToastSuccess,
} from '../../../shared/components/toast/toast';
import { useAdminAuthCheck } from '../../../shared/hooks/use-admin-auth-check.hook';
import { useDebounce } from '../../../shared/hooks/use-debounce.hook';
import { adminService } from '../../../shared/services/admin/admin.service';
import {} from '../../article/index.styles';
import { ArticlesListContainer } from '../../articles/index.styles';
import AppCheckbox from '../../../shared/components/checkbox';

const AdminArticlesPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [totalItems, setTotalItems] = useState(0);
  const [listOfArticles, setListOfArticles] = useState<any>();
  const [search, setSearch] = useState('');
  const [listOfFilteredArticles, setListOfFilteredArticles] = useState<any>();
  const navigate = useNavigate();

  const changingValue = useDebounce(search, 500);

  console.log('changingValue', changingValue);
  useAdminAuthCheck();

  const { refetch: articleRefetch } = useQuery<any, any>(
    ['adminArticles', { limit, page }],
    () => adminService.getArticles(limit, page),

    {
      keepPreviousData: true,
      onSuccess(res: any) {
        setListOfArticles(res.data.data);
        setTotalItems(res.data.total);
      },
      onError: () => {
        showToastError('Щось пішло не так');
      },
    }
  );

  const { refetch } = useQuery<any, any>(
    ['adminSearchedArticles', { changingValue }],
    () => adminService.getArticlesByTitle(changingValue),

    {
      keepPreviousData: true,
      onSuccess(res: any) {
        setListOfFilteredArticles(res.data);
        console.log(res.data);
        //listOfFilteredArticles(res.data.data);
      },
      onError: () => {
        // showToastError('Щось пішло не так');
      },
    }
  );

  const deleteArticle = useMutation(
    async (id: any) => adminService.deleteArticleById(id),
    {
      onSuccess: res => {
        showToastSuccess('Успішно видалено');
        articleRefetch();
        refetch();
      },
      onError: (error: any) => {
        console.log(error);
        showToastError('помилка');
      },
    }
  );

  const updateArticleSlider = useMutation(
    async ({ id, sliderStatus }: { id: number; sliderStatus: boolean }) =>
      adminService.updateArticleSlider(id, sliderStatus),
    {
      onSuccess: res => {
        const message = res?.data?.slider
          ? 'Успішно добавленно статью в слайдер'
          : 'Успішно видаленно статью з слайдера';
        showToastSuccess(message);
        articleRefetch();
        refetch();
      },
      onError: (error: any) => {
        console.log(error);
        showToastError('помилка');
      },
    }
  );

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
  };

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleDelete = (id: any) => {
    deleteArticle.mutate(id);
  };

  const handleUpdate = (id: number) => {
    navigate(`/admin/articles/update/${id}`);
  };

  const handleChangeSlider = async (id: number, value: boolean) => {
    await updateArticleSlider.mutateAsync({ id, sliderStatus: value });
  };

  return (
    <AdminContentWrapper>
      <ToastContainer />

      <AdminContentContainer>
        <input
          type="text"
          placeholder="Пошук..."
          value={search}
          style={{
            margin: '10px 0 10px 0px',
            paddingLeft: '8px',
            width: '30%',
            height: 50,
            borderRadius: 8,
          }}
          onChange={handleInputChange}
        />
        {search?.trim() == '' ? (
          <>
            <ArticlesListContainer>
              {listOfArticles !== undefined &&
                listOfArticles.map((article: any) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: 20,
                      }}
                    >
                      <ArticleCard
                        onClick={() => null}
                        id={article?.id}
                        name={article?.author}
                        title={article?.title}
                        image={article?.featured_image}
                        category={article.categories?.[0]}
                      />
                      <AppCheckbox
                        name="slider"
                        label="Добавить статью в slider"
                        checked={article?.slider}
                        onChange={e =>
                          handleChangeSlider(article.id, e.target.checked)
                        }
                      />
                      <div style={{ marginBottom: '8px' }}>
                        <AppButton
                          text="Редактувати"
                          width="250px"
                          onClick={() => handleUpdate(article.id)}
                        />
                      </div>
                      <AppButton
                        text="Видалити"
                        width="250px"
                        onClick={() => handleDelete(article.id)}
                      />
                    </div>
                  );
                })}
            </ArticlesListContainer>
            <Pagination
              totalItems={totalItems}
              itemsPerPage={limit}
              currentPage={page}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <>
            <ArticlesListContainer>
              {listOfFilteredArticles?.length == 0
                ? 'нічого немає'
                : listOfFilteredArticles?.map((article: any) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <ArticleCard
                          onClick={() => null}
                          id={article.id}
                          name={article.author}
                          title={article.title}
                          image={article.featured_image}
                          category={article.categories[0]}
                        />
                        <AppButton
                          text="Видалити"
                          width="250px"
                          onClick={() => handleDelete(article.id)}
                        />
                      </div>
                    );
                  })}
            </ArticlesListContainer>
          </>
        )}
      </AdminContentContainer>
    </AdminContentWrapper>
  );
};

export default AdminArticlesPage;
