import styled from 'styled-components';
import { COLORS } from '../../../../shared/theme';

export const HomeWebinarsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top: 40px;
`;

export const HomeWebinarDesc = styled.div`
  font-family: OpenSans-Regular;
  text-align: center;
  font-size: 24px;
  color: ${COLORS.greyPrimary};
  margin: 0 20px 48px 20px;
  @media only screen and (max-width: 1030px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 720px) {
    font-size: 15px;
  }
`;

export const HomeWebinarsContainer = styled.div`
  max-width: 1240px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HomeWebinarsTitle = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 50px 0;
  @media only screen and (max-width: 1392px) {
    margin: 20px 0;
    justify-content: center;
  }
  //@media only screen and (max-width:1300px ) {
  //  margin-top: 400px;
  //}
  //@media only screen and (max-width:1000px ) {
  //  margin-top: 350px;
  //}
  //@media only screen and (max-width:755px ) {
  //  margin-top: 300px;
  //}
  //@media only screen and (max-width: 610px) {
    //width: 100%;
    //margin-top: 300px;
    //margin: 10px 0;
    //justify-content: center;
  }
`;
export const ArticlesTitleText = styled.div`
  font-family: Montserrat-SemiBold;
  font-size: 36px;
  margin: 0 0 5px 16px;
  @media only screen and (max-width: 1030px) {
    font-size: 30px;
    margin: 0 0 5px 9px;
  }
  @media only screen and (max-width: 610px) {
    font-size: 18px;
    margin: 0 0 3px 5px;
  }
`;

export const ArticlesImage = styled.img`
  @media only screen and (max-width: 1030px) {
    width: 55px;
    margin: 0 0 0 30px;
  }
  @media only screen and (max-width: 610px) {
    width: 35px;
    margin: 0 0 0 0px;
  }
`;
export const WebinarsFilter = styled.div`
  //width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
  @media only screen and (max-width: 1392px) {
    margin: 0 30px 20px 0;
  }
  @media only screen and (max-width: 1016px) {
    max-width: 800px;
  }
  @media only screen and (max-width: 780px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 981px) {
    gap: 10px;
    margin: 0 0 15px 0;
  }
`;

export const ArticlesSlider = styled.div`
  width: 100%;
`;
