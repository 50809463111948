import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Outlet } from 'react-router-dom';
import Footer from '../footer';
import Navbar from '../Navbar/Navbar';
import { HomeContainerWrapper } from './index.styled';
import { calculateEngagementTimeMsec } from '../../utils/calculateEngagementTimeMsec';
import { getCookie } from '../../utils/getCookieDomain';
import { getQueryParam } from '../../utils/getQueryParam';

const HomeContainer = () => {
  useEffect(() => {
    const startTime = Date.now();
    const engagementTimeMsec = calculateEngagementTimeMsec(startTime);

    const dataGoogle = {
      client_id: getCookie('_ga') || 'unknown_client',
      gclid: getQueryParam('gclid') || undefined,
      campaignId: getQueryParam('campaignId') || 'N/A',
      campaign: getQueryParam('utm_campaign') || 'N/A',
      source: getQueryParam('utm_source') || 'N/A',
      medium: getQueryParam('utm_medium') || 'N/A',
      term: getQueryParam('utm_term') || 'N/A',
      content: getQueryParam('utm_content') || 'N/A',
      sessionId: uuidv4(),
      engagementTimeMsec: engagementTimeMsec,
    };

    localStorage.setItem('dataGoogle', JSON.stringify(dataGoogle));

    console.log('Google Analytics data saved to localStorage:', dataGoogle);
  }, []);

  return (
    <HomeContainerWrapper>
      <Navbar />
      <Outlet />
      <Footer />
    </HomeContainerWrapper>
  );
};

export default HomeContainer;
