import React from 'react';
import {
  WebinarsFilter,
  ArticlesImage,
  ArticlesTitleText,
  HomeWebinarsContainer,
  HomeWebinarsTitle,
  HomeWebinarsWrapper,
  HomeWebinarDesc,
} from './home-webinars.styles';
import logo from '../../../../assets/icons/shortLogo.svg';
import FilterButton from '../../../../shared/components/filter-button';
import { articleConstButtons } from '../../../../shared/utils';
import HorizontalSlider from '../../../../shared/components/sliders/horisontal-slider';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../../../shared/hooks/useScreenSize';

const HomeWebinars = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  return (
    <HomeWebinarsWrapper>
      <HomeWebinarsContainer>
        <HomeWebinarsTitle>
          <ArticlesImage src={logo} alt="webinars" />
          <ArticlesTitleText>Вебінари</ArticlesTitleText>
        </HomeWebinarsTitle>
        <p style={{ fontSize: 40, fontWeight: 600, marginBottom: 50 }}>
          Coming soon...
        </p>
        <HomeWebinarDesc>
          Ми вже працюємо над тим, щоб в цьому розділі зʼявились вебінари, від
          топових спеціалістів з України. Їх можна буде придбати та переглядати
          в особистому кабінеті не залежно від підписки на статті.
        </HomeWebinarDesc>

        {/*<WebinarsFilter>
          {width >= 610 ? (
            articleConstButtons.map((button, index) => {
              return (
                <FilterButton
                  key={index}
                  onClick={() => navigate('/webinars')}
                  text={button.name}
                  id={index}
                  width={width <= 981 ? '180px' : '230px'}
                />
              );
            })
          ) : (
            <FilterButton
              key={'index'}
              onClick={() => navigate('/articles')}
              text={'Категорії'}
              id={1}
              width={width <= 440 ? `${width * 0.7}px` : '400px'}
            />
          )}
        </WebinarsFilter>*/}
        {/*<HorizontalSlider />*/}
      </HomeWebinarsContainer>
    </HomeWebinarsWrapper>
  );
};

export default HomeWebinars;
