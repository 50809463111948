export const getCookie = (
  name: string,
  domain: string = '.iqdental.com.ua'
): string | null => {
  const allCookies = document.cookie.split(';');

  const cookie = allCookies.find(cookie => {
    const [cookieName, cookieValue] = cookie.trim().split('=');
    return cookieName === name && document.domain.endsWith(domain);
  });

  return cookie ? cookie.split('=').slice(1).join('=') : null;
};
