import styled from 'styled-components';
import { COLORS } from '../../../shared/theme';

export const LoginContainer = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  padding-top: 10%;
  //justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #010101 0%, #011d45 50%, #013482 100%);
  @media only screen and (max-width: 800px) {
    justify-content: flex-start;
    padding-top: 90px;
  }
`;

export const MailForm = styled.div`
  margin-bottom: 16px;
`;

export const InputForm = styled.input`
  font-size: 20px;
  padding: 8px 6px 8px 6px;
  border-width: 1px;
  &:focus {
    outline: none;
  }
`;

export const LoginWrapper = styled.div`
  min-height: 454px;
  border-radius: 32px;

  background-color: ${COLORS.white};
  max-width: 1240px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px;
  margin: 0 30px;
  gap: 40px;
  margin-bottom: 50px;
  @media only screen and (max-width: 886px) {
    padding: 20px;
    gap: 20px;
  }
  @media only screen and (max-width: 800px) {
    padding: 40px;
  }
  @media only screen and (max-width: 476px) {
    padding: 24px;
    margin: 0 10px;
    border-radius: 16px;
  }
  @media only screen and (max-width: 328px) {
    padding: 10px;
    margin: 0 5px;
  }
`;

export const LoginFormContainer = styled.div`
  position: relative;
  flex: 1;
  flex-basis: 50%;
  min-width: 47%;
  position: relative;
  @media only screen and (max-width: 981px) {
  }
  @media only screen and (max-width: 630px) {
  }
`;

export const LoginTitle = styled.div`
  //display: none;
  justify-content: center;
  padding-bottom: 24px;
  font-size: 18px;
  color: #000;
  font-family: 'Diia-Medium';
  @media only screen and (max-width: 528px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 800px) {
    display: flex;
  }
`;

export const LoginImage = styled.img`
  width: 100%;
`;

export const FormContainer = styled.div`
  flex: 1;
  //width: 450px;
  //min-width: 450px;
  @media only screen and (max-width: 943px) {
    //min-width: none;
  }
`;

export const BackButtonWrapper = styled.div`
  position: absolute;
  left: -25px;
  top: -28px;

  @media only screen and (max-width: 887px) {
    left: -5px;
    top: -17px;
  }

  @media only screen and (max-width: 800px) {
    left: 0;
    top: 0;
  }
`;

export const BackButtonIcon = styled.svg`
  width: 24px;
  height: 24px;
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  cursor: pointer;
  transition: stroke 0.3s ease;

  &:hover {
    stroke: #007bff;
  }
`;

export const ImageContainer = styled.div`
  min-width: 50%;
  flex-basis: 50%;
  border-radius: 32px;
  height: 100%;
  padding: 0 12px 0 12px;
  display: flex;
  align-items: center;
`;

export const LogoImage = styled.button`
  margin: 10px 0 40px 0;
  border: none;
  background: none;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 60px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    // width: 400px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 447px) {
    width: 100%;
  }
`;

export const ButtonLoginContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    // width: 400px;
    width: 100%;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 447px) {
    width: 100%;
  }
`;

export const MoveButtonContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    width: 400px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 447px) {
    width: 100%;
  }
`;

export const ButtonLine = styled.div`
  width: 100%;
  background-color: 'gray';
  height: 1;
  margin: 20px 0 20px 0;
`;

export const DiiaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const DiiaContainerForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const DiiaGerb = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
`;

export const DiiaImage = styled.img`
  width: 130px;
  @media only screen and (max-width: 440px) {
    width: 90px;
  }
`;

export const DiiaQR = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DiiaTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: start;
  gap: 10px;
`;

export const DiiaRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: 10px;
`;

export const DiiaLink = styled.div`
  font-size: 12px;
  text-align: left;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: 'Diia-Regular';
  @media only screen and (max-width: 440px) {
    font-size: 12px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const DiiaText = styled.div`
  font-size: 12px;
  text-align: left;
  margin-bottom: 10px;
  font-family: 'Diia-Regular';
  @media only screen and (max-width: 440px) {
    font-size: 12px;
  }
`;

export const DiiaCondition = styled.button`
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  margin: 0;
`;

export const DiiaConditionText = styled.h4`
  font-size: 12px;
  font-family: 'Diia-Regular';
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  margin: 0;
  &:hover {
    background: -webkit-linear-gradient(#007eff, #aa2b8e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const TimerContainer = styled.div`
  display: flex;
  margin-top: 50px;
  margin-bottom: 20px;
  gap: 10px;
`;

export const TimerLink = styled.a`
  width: 100%;
  text-decoration: none;
`;

export const TimerText = styled.p`
  font-size: 12px;
  font-family: 'Diia-Regular';
  color: #000;
`;

export const Restore = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const LoginLink = styled.span`
  cursor: pointer;

  color: blue;
`;

export const CheckboxWrapper = styled.div`
  z-index: 1000;

  .checkbox {
    --bg: #fff;
    --brdr: #d1d6ee;
    --brdr-actv: #1e2235;
    --brdr-hovr: #bbc1e1;
    --dur: calc((var(--size, 2) / 2) * 0.6s);

    display: inline-block;
    width: calc(var(--size, 1) * 22px);
    position: relative;

    &:after {
      content: '';
      width: 100%;
      padding-top: 100%;
      display: block;
    }

    > * {
      position: absolute;
    }

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      background-color: var(--bg);
      border-radius: calc(var(--size, 1) * 4px);
      border: calc(var(--newBrdr, var(--size, 1)) * 1px) solid;
      color: var(--newBrdrClr, var(--brdr));
      outline: none;
      left: 0;
      margin: 0;
      padding: 0;

      &:hover,
      &:checked {
        transition-delay: 0;
        --newBrdr: calc(var(--size, 1) * 2);
      }

      &:hover {
        --newBrdrClr: var(--brdr-hovr);
      }

      &:checked {
        transition: all calc(var(--dur) / 3) linear;
        --newBrdrClr: var(--brdr-actv);
        transition-delay: calc(var(--dur) / 1.3);
      }

      &:checked + svg {
        --dashArray: 16 93;
        --dashOffset: 109;
      }
    }

    svg {
      fill: none;
      left: 0;
      pointer-events: none;
      stroke: var(--stroke, var(--border-active));
      stroke-dasharray: var(--dashArray, 93);
      stroke-dashoffset: var(--dashOffset, 94);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
      top: 0;
      transition:
        stroke-dasharray var(--dur),
        stroke-dashoffset var(--dur);

      &,
      input {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }
`;

export const SideText = styled.p`
  font-family: Montserrat-Regular;
  font-size: 14px;
  span {
    font-weight: bold;
  }
`;

export const ContainerAddDevice = styled.div`
  margin-bottom: 10px;
  text-align: center;
`;

export const ContainerAddDeviceSuccess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerAddDeviceSuccessImg = styled.img`
  width: 30px;
  margin-left: 15px;
`;

export const ContainerLinkAddDevice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export const CopyButton = styled.button`
  font-family: 'Montserrat-Medium';
  margin-left: 14px;
  background: ${({ disabled }) =>
    disabled ? COLORS.secondaryGrey : COLORS.primaryBlue};
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.primaryWhite};
  padding: 8px;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;
  &:hover {
    background: ${({ disabled }) =>
      disabled ? COLORS.secondaryGrey : '#0D3ABD'};
  }
  &:active {
    background: ${({ disabled }) =>
      disabled ? COLORS.secondaryGrey : '#011D46'};
  }
`;

export const AddButton = styled.button`
  font-family: 'Montserrat-Medium';
  margin-left: 14px;
  background: ${({ disabled }) =>
    disabled ? COLORS.secondaryGrey : COLORS.primaryBlue};
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.primaryWhite};
  padding: 8px;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;
  &:hover {
    background: ${({ disabled }) =>
      disabled ? COLORS.secondaryGrey : '#0D3ABD'};
  }
  &:active {
    background: ${({ disabled }) =>
      disabled ? COLORS.secondaryGrey : '#011D46'};
  }

  @media only screen and (max-width: 800px) {
    margin-top: 5px;
  }
`;

export const SideTitle = styled.p`
  font-family: Montserrat-Medium;
  font-size: 16px;
`;

export const SideTextTitle = styled.p`
  font-family: Montserrat-Medium;
  font-size: 14px;
  margin: 10px 0;
`;

export const AlternativeContainer = styled.div`
  border-top-width: 4px;
  border-top: 1px solid grey;
  width: 100%;
  padding-top: 50px;
`;

export const AlternativeButton = styled.button`
  background-color: transparent;
  color: #000000;
  border-radius: 8px;
  border-width: 1px;
  border-color: grey;
  cursor: pointer;
  padding: 10px 20px;
  width: 100%;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
  }
  &:disabled {
    color: #1010104d;
  }
`;

export const AlternativeText = styled.p`
  font-family: 'Diia-Regular';
  font-size: 14px;
`;
