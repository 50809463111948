import React from 'react';
import {
  FooterBottom,
  FooterContainer,
  FooterCredential,
  FooterCredentialColumn,
  FooterMobileBox,
  FooterSocials,
  FooterText,
  FooterTitle,
  FooterWrapper,
} from './index.styles';
import instagram from '../../../assets/icons/Instagram.svg';
import facebook from '../../../assets/icons/facebook.svg';
import telegram from '../../../assets/icons/telegram.svg';
import { useNavigate } from 'react-router-dom';
import { RouterKeys } from '../../../router/keys';
import InnovixAdd from './innovix';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterCredential>
          <FooterMobileBox>
            <FooterCredentialColumn>
              <FooterTitle>Навчання</FooterTitle>
              <FooterText onClick={() => navigate(RouterKeys.ARTICLES)}>
                Статті
              </FooterText>
              <FooterText onClick={() => navigate(RouterKeys.WEBINARS)}>
                Вебінари
              </FooterText>
            </FooterCredentialColumn>
            <FooterCredentialColumn>
              <FooterTitle>IQDental</FooterTitle>
              <FooterText onClick={() => navigate(RouterKeys.POLICY)}>
                Конфіденційність
              </FooterText>
              <FooterText onClick={() => navigate(RouterKeys.OFFER_CONTRACT)}>
                Договір оферти
              </FooterText>
            </FooterCredentialColumn>
          </FooterMobileBox>

          <FooterCredentialColumn>
            <FooterTitle>Контакти</FooterTitle>
            <FooterText>
              <a href="tel:+380680766963">+38(068)-076-69-63</a>
            </FooterText>
            <FooterText>
              <a href="tel:+380505971005">+38(050)-597-10-05</a>
            </FooterText>
            <FooterText>
              <a href="mailto:IQDentalinfo@gmail.com">info@iqdental.com.ua</a>
            </FooterText>
          </FooterCredentialColumn>
        </FooterCredential>
        <FooterSocials>
          <a
            target="_blank"
            href="https://www.instagram.com/iqdental.ua?igsh=amxxZ2g4bDB0NHp4"
          >
            <img src={instagram} alt="instagram" />
          </a>
          <img src={facebook} alt="facebook" />
          <a target="_blank" href="https://t.me/IQDentalSupport_bot">
            <img src={telegram} alt="telegram" />
          </a>
        </FooterSocials>
      </FooterContainer>
      <FooterBottom>Ⓒ 2024 IQDental. Всі права захищені.</FooterBottom>
      <InnovixAdd />
    </FooterWrapper>
  );
};

export default Footer;
