import React, { FC } from 'react';
import { useWindowSize } from '../../hooks/useScreenSize';
import AppButton from '../app-button/AppButton';
import { Container, PriceData, PriceText } from './index.styles';
import { paymentService } from '../../services/payment.service';
import { userService } from '../../services/user.service';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../store/auth';

type IPriceBox = {
  price: string;
  data: string;
  isWhite: boolean;
  id: number;
  fbc: string;
  fbp: string;
};

const PriceBox: FC<IPriceBox> = ({ price, data, isWhite, id, fbc, fbp }) => {
  const { width } = useWindowSize();
  const { isAuth } = useAuth();
  const navigate = useNavigate();

  const createPayment = useMutation(
    async (data: any) =>
      paymentService.createPaymentSub(data.amount, data.idSub),
    {
      onSuccess: response => {
        window.location.href = response?.data?.pageUrl;
      },
      onError: (error: any) => {
        console.log(error);
      },
    }
  );
  const createCookies = useMutation(
    async (data: { fbc: string; fbp: string }) =>
      userService.createCookiesFb(data.fbc, data.fbp),
    {
      onSuccess: () => {
        createPayment.mutate({ amount: Number(price), idSub: Number(id) });
      },
      onError: (error: any) => {
        console.log(error);
      },
    }
  );

  const handlerCreatePayment = () => {
    if (fbc || fbp) {
      createCookies.mutate({ fbc, fbp });
    } else {
      createPayment.mutate({ amount: Number(price), idSub: Number(id) });
    }
  };

  return (
    <Container isWhite={isWhite}>
      <PriceData>{data}</PriceData>
      <PriceText>{price} грн</PriceText>
      <AppButton
        onClick={() => (isAuth ? handlerCreatePayment() : navigate('/sign-in'))}
        text="Обрати"
        width={width < 589 ? '120px' : '200px'}
        disabled={false}
      />
    </Container>
  );
};

export default PriceBox;
