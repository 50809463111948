import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'shared/hooks/useScreenSize';
import {
  DiiaContainerForm,
  ImageContainer,
  LoginFormContainer,
  LoginImage,
  LoginTitle,
  MailForm,
  SideText,
  BackButtonIcon,
  BackButtonWrapper,
} from './index.styles';

// @ts-ignore
import backImage from '../../../assets/image/logo-phone.jpg';
import { FormInput } from 'shared/components/form-input';
import { Form } from 'react-final-form';
import { useMutation } from 'react-query';
import { userService } from 'shared/services/auth.service';
import SignUpForm from 'shared/components/signup';
import SignInForm from 'shared/components/signin';
import { phoneValidationSchema } from './validate-phone';
import { fingerprintHandler } from '../../../shared/utils/fingerprint';

const AppLogin = () => {
  const [phone, setPhone] = useState('');
  const [visitorId, setVisitorId] = useState('');
  const [firstRender, setFirstRender] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(true);
  const [formError, setFormError] = useState('');
  const [clickVerify, setClickVerify] = useState(false);
  const [verifyAuth, setVerifyAuth] = useState(false);
  const navigate = useNavigate();

  const buttonBack = () => {
    return navigate('/sign-in');
  };

  useEffect(() => {
    const fetchFingerprint = async () => {
      const id = await fingerprintHandler();
      if (id) {
        setVisitorId(id);
      }
      setFirstRender(true);
    };

    fetchFingerprint();
  }, []);

  const verify = useMutation(
    async (body: any) => userService.verifyPhone(body),
    {
      onSuccess: (res: any) => {
        setIsPhoneVerified(res.data);
        setVerifyAuth(true);
      },

      onError: (error: any) => {
        console.log(error);
      },
    }
  );

  const verifyPhone = async (values: { phoneConfirm: string }) => {
    setFormError('');
    try {
      await phoneValidationSchema.validate(values, { abortEarly: false });
      localStorage.setItem('phone', values.phoneConfirm);
      setPhone(values.phoneConfirm);
      verify.mutate({
        phone: values.phoneConfirm,
      });
      setClickVerify(true);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setFormError(error?.errors?.[0]);
      }
    }
  };

  const { width } = useWindowSize();
  return (
    <>
      <LoginFormContainer>
        <BackButtonWrapper>
          <BackButtonIcon
            onClick={buttonBack}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M10 19l-7-7 7-7" />
            <line x1="3" y1="12" x2="21" y2="12" />
          </BackButtonIcon>
        </BackButtonWrapper>

        <LoginTitle>Увійти</LoginTitle>
        <DiiaContainerForm>
          <MailForm>
            <Form
              onSubmit={verifyPhone}
              initialValues={{
                phoneConfirm: localStorage.getItem('phone') || '',
              }}
              // validate={() => {}} //from Alex
              render={({ handleSubmit }) => (
                <>
                  <label>Контактний номер телефону</label>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '4px',
                      height: '100%',
                    }}
                  >
                    <FormInput
                      name="phoneConfirm"
                      passedValue="380"
                      backError={formError}
                    />
                    <button
                      disabled={clickVerify || !visitorId}
                      style={{
                        height: '50px',
                        borderRadius: '8px',
                        borderWidth: '1px',
                        color: 'white',
                        background: 'black',
                        cursor: 'pointer',
                      }}
                      onClick={handleSubmit}
                    >
                      Клац**
                    </button>
                  </div>
                  {!isPhoneVerified && (
                    <SideText>
                      Ніхто не подзвонив?{' '}
                      <span
                        onClick={handleSubmit}
                        style={{
                          fontSize: 14,
                          color: 'blue',
                          cursor: 'pointer',
                        }}
                      >
                        Повторить дзвінок
                      </span>
                    </SideText>
                  )}

                  {formError && (
                    <div
                      style={{
                        fontSize: '14px',
                        color: 'red',
                        marginTop: '8px',
                      }}
                    >
                      * {formError}
                    </div>
                  )}
                  {!visitorId && firstRender && (
                    <div
                      style={{
                        padding: '10px',
                        backgroundColor: '#fff4e5',
                        border: '1px solid #ffa500',
                        borderRadius: '5px',
                        color: '#ff4500',
                        fontSize: '14px',
                        marginTop: '8px',
                        lineHeight: '1.5',
                        textAlign: 'center',
                      }}
                    >
                      <strong>Увага!</strong>
                      <p style={{ margin: '10px 0' }}>
                        Ми визначили, що у вас увімкнено VPN, блокувальники
                        реклами або інші інструменти, які можуть перешкоджати
                        вашій ідентифікації. Це може зробити неможливим
                        реєстрацію або вхід за номером телефону.
                      </p>
                      <ul
                        style={{
                          padding: '0',
                          margin: '10px 0',
                          listStyleType: 'none',
                        }}
                      >
                        <li>• Вимкніть VPN або анонімайзери.</li>
                        <li>• Відключіть блокувальники реклами.</li>
                        <li>• Оновіть сторінку після внесення змін.</li>
                      </ul>
                      <p>
                        Дякуємо за розуміння! Якщо виникли питання, звертайтеся
                        до нашої служби підтримки.
                      </p>
                    </div>
                  )}
                </>
              )}
            />
          </MailForm>

          {verifyAuth &&
            (isPhoneVerified ? (
              <SignInForm phone={phone} visitorId={visitorId} />
            ) : (
              <SignUpForm phone={phone} visitorId={visitorId} />
            ))}
        </DiiaContainerForm>
      </LoginFormContainer>

      {width > 800 && (
        <ImageContainer>
          <LoginImage
            src={backImage}
            alt="img"
            style={{ borderRadius: '32px' }}
          />
        </ImageContainer>
      )}
    </>
  );
};

export default AppLogin;
