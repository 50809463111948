import React, { FC, useState } from 'react';
import ArrowRight from '../../../../assets/icons/ArrowRight';
import {
  Arrow,
  ArrowContainer,
  Container,
  Description,
  Title,
  TitleText,
} from './FAQ.styles';

const FAQItem: FC<any> = ({ title, desc }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDescription = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Container isOpen={isOpen}>
      <Title onClick={toggleDescription}>
        <TitleText>{title}</TitleText>
        <ArrowContainer isOpen={isOpen}>
          <Arrow isOpen={isOpen}>
            <ArrowRight
              color={isOpen ? '#ffff' : '#0D1282'}
              rotate={isOpen ? 90 : 0}
            />
          </Arrow>
        </ArrowContainer>
      </Title>
      <Description
        isOpen={isOpen}
        dangerouslySetInnerHTML={{ __html: desc }}
      ></Description>
    </Container>
  );
};

export default FAQItem;
