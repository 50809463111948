import React, { FC } from 'react';
import { PageButton, PageItem, PaginationList } from './index.styles';

function Pagination({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}: any) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getDisplayedPages = () => {
    const maxPages = 10;
    const half = Math.floor(maxPages / 2);

    let start = Math.max(1, currentPage - half);
    let end = Math.min(totalPages, currentPage + half);

    if (currentPage <= half) {
      start = 1;
      end = Math.min(maxPages, totalPages);
    }

    if (currentPage + half >= totalPages) {
      end = totalPages;
      start = Math.max(1, totalPages - maxPages + 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const displayedPages = getDisplayedPages();

  return (
    <PaginationList>
      {currentPage > 1 && (
        <PageItem>
          <PageButton onClick={() => onPageChange(1)}>{'<<'}</PageButton>
        </PageItem>
      )}

      {displayedPages.map(page => (
        <PageItem key={page}>
          <PageButton
            isActive={page === currentPage}
            onClick={() => onPageChange(page)}
          >
            {page}
          </PageButton>
        </PageItem>
      ))}

      {currentPage < totalPages && (
        <PageItem>
          <PageButton onClick={() => onPageChange(totalPages)}>
            {'>>'}
          </PageButton>
        </PageItem>
      )}
    </PaginationList>
  );
}

export default Pagination;
